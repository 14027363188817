import React, { useEffect, useMemo, useRef, useState } from 'react';
import App, { AppContext, AppProps } from 'next/app';
import Script from 'next/script';
import { I18nextProvider, initI18n, Messages } from '@wix/wix-i18n-config';
import { WixDesignSystemProvider } from '@wix/design-system';
import { RootEssentialsProvider } from '@wix/fe-essentials-standalone/react';
import { createEssentials } from '@wix/fe-essentials-standalone';
import type { ExperimentsBag } from '@wix/wix-experiments';
import { Article, Category } from '@wix/answers-api';
import { User } from '@wix/ambassador-identity-account-v2-account/build/cjs/types.impl';
import { HEADER_FOOTER_SUPPORT_LANGUAGES, LOCALES } from '../constants';
import { PageTemplate, PageTemplateData } from '../components/PageTemplate';
import { Context, answersApi } from '../context';
import { PageType, PageSeo, SessionBI, SessionBIType } from '../types';
import { Error } from '../components/Error';
import { NotificationBar } from '../components/NotificationBar';
import { GlobalLoader } from '../components/GlobalLoader';
import HistoryProvider from '../hooks/useRouteHistory';
import { abTranslate } from '@wix/ab-translate/browser';
import {
  hasAnswersSSO,
  isPersonalPage,
  loginWithAnswersSSO,
} from '../utils/login';
import '../styles/globals.scss';
import '@wix/design-system/styles.global.css';
import 'normalize.css/normalize.css';
import { sessionDebounceBI } from '../utils/bi';
import moment from 'moment';
import { fetchSessionBI } from '../hooks/useSessionBI';

type PageData = {
  article?: Article;
  category?: Category;
};

type PageProps = {
  pageData: PageData;
  pageType: PageType;
  itemId?: string;
  itemType?: string;
  isMobile: boolean;
  isTablet: boolean;
  categoriesTree: Category[];
  experiments: ExperimentsBag;
  pageSeo: PageSeo;
  hasError?: boolean;
  messageError?: string;
  statusCode?: number;
  user?: User;
  loggedInAccountId?: string;
};

type CustomAppProps = AppProps & {
  locale: string;
  messages: Messages;
  experiments: ExperimentsBag;
  pageProps: PageProps;
};

const CustomApp = ({
  Component,
  pageProps,
  locale,
  messages,
}: CustomAppProps) => {
  const {
    pageType,
    itemType,
    isMobile,
    categoriesTree,
    experiments,
    pageSeo,
    hasError,
    messageError,
    statusCode,
    itemId,
    pageData,
    isTablet,
    isLoggedInUser,
    isWixStudioUser,
    isPartner,
    isWixEmployee,
    user,
    loggedInAccountId,
  } = pageProps;

  const i18n = initI18n({
    locale,
    messages: experiments ? abTranslate(experiments, messages) : messages,
  });

  const fetchSessionDebounceBI = sessionDebounceBI(fetchSessionBI, 300);

  const first = useRef(true);

  const [pageTemplateData, setPageTemplateData] = useState<PageTemplateData>({
    pageData,
    itemId,
    itemType,
    hasError: hasError || false,
  });

  const [sessionBI, setSessionBI] = useState<SessionBI>({
    lastSessionDate: moment(),
    selfServiceSessionId: null,
  });

  const essentials = createEssentials({
    environment: {
      language: locale,
      artifactId: 'kb-client-nextjs',
      version: '0.1.0',
    },
    experiments: { bag: pageProps.experiments },
    fedops: {},
    httpClient: {
      baseURL: `/${locale}/`,
    },
  });

  useEffect(() => {
    const userIsLoggedInToWixOnly = isLoggedInUser && !hasAnswersSSO();
    if (isLoginRequired(pageType, isLoggedInUser) || userIsLoggedInToWixOnly) {
      loginWithAnswersSSO(location.href, locale);
    }
    if (!isLoggedInUser) {
      localStorage.setItem('hc_session_bi_type', SessionBIType.VISITOR);
    }
  }, [isLoggedInUser, locale, pageType]);

  useEffect(() => {
    if (first.current) {
      first.current = false;
      return;
    }
    setPageTemplateData({
      pageData,
      itemId,
      itemType,
      hasError: hasError || false,
    });
  }, [pageData, itemId, itemType, hasError]);

  const thirdPartyScripts = useMemo(() => {
    if (globalThis?.location && location.hostname === 'localhost') {
      return null;
    }

    const integrationLocale = HEADER_FOOTER_SUPPORT_LANGUAGES.includes(
      locale as string
    )
      ? locale
      : LOCALES.EN;

    return (
      <>
        <Script src="https://apps.wix.com/answers-wix-integration/cookie-policy/consent-manager.js" />
        <Script src="https://apps.wix.com/answers-wix-integration/cookie-policy/tag-manager.js" />
        <Script
          src={`https://apps.wix.com/answers-wix-integration/cookie-policy/${integrationLocale}/setup.js`}
          strategy="lazyOnload"
        />
      </>
    );
  }, [locale, globalThis?.location?.hostname]);

  return (
    <Context.Provider
      value={{
        answersApi,
        isMobile,
        isTablet,
        experiments,
        categoriesTree,
        isLoggedInUser,
        isWixStudioUser,
        pageTemplateData,
        isPartner,
        isWixEmployee,
        setPageTemplateData,
        pageType,
        sessionBI,
        setSessionBI,
        fetchSessionDebounceBI,
        user,
        loggedInAccountId,
      }}
    >
      <HistoryProvider>
        <RootEssentialsProvider essentials={essentials}>
          <I18nextProvider i18n={i18n}>
            <WixDesignSystemProvider features={{ newColorsBranding: true }}>
              <NotificationBar pageType={pageType} />
              <PageTemplate pageType={pageType} pageSeo={pageSeo}>
                <GlobalLoader />
                {hasError ? (
                  <Error
                    statusCode={statusCode as number}
                    messageError={messageError}
                  />
                ) : isLoginRequired(pageType, isLoggedInUser) ? (
                  <></>
                ) : (
                  <Component {...pageProps} />
                )}
              </PageTemplate>
              {thirdPartyScripts}
            </WixDesignSystemProvider>
          </I18nextProvider>
        </RootEssentialsProvider>
      </HistoryProvider>
    </Context.Provider>
  );
};

CustomApp.getInitialProps = async (context: AppContext) => {
  let { locale } = context.router;
  if (!Object.values(LOCALES).includes(locale as string)) {
    locale = LOCALES.EN;
  }
  const { pageProps } = await App.getInitialProps(context);
  const messages = await import(`../locales/messages_${locale}.json`);
  return { pageProps, locale, messages };
};

const isLoginRequired = (pageType: PageType, isLoggedInUser?: boolean) =>
  isPersonalPage(pageType) && (!isLoggedInUser || !hasAnswersSSO());

export default CustomApp;
