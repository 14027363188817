/* eslint-disable max-params*/
import { useRouter } from 'next/router';
import { useBI } from '../../hooks/useBI';
import {
  clickTheSeeAllResultsLink,
  askingAQuestion,
  clickOnAnswer,
  hcSearchDropdownSourceSectionOnView,
} from '@wix/bi-logger-customer-care-data/v2';
import { useCallback } from 'react';
import {
  mapArticleTypeToItemType,
  mapResourceTypeToItemType,
  mapPageTypeToSourceName,
} from '../../utils/bi';
import {
  ArticleSearchResultItem,
  ArticleSourceType,
  PageType,
} from '../../types';
import { ArticleType } from '@wix/answers-api';
import { BI } from '../../constants';

export const useSearchBarBI = () => {
  const { sendBIEvent } = useBI();
  const { locale } = useRouter();

  const clickAllResultsBI = useCallback(
    async (
      inputText: string,
      pageType: PageType,
      searchSessionId: string,
      clear: () => void,
      itemId?: string,
      itemType?: string,
      resourceType?: ArticleSourceType | string
    ) => {
      const typeTrigger =
        resourceType === 'general'
          ? 'general'
          : mapResourceTypeToItemType(resourceType as ArticleSourceType);
      await sendBIEvent(
        clickTheSeeAllResultsLink({
          source_name: mapPageTypeToSourceName(pageType),
          item_id: itemId,
          item_type: itemType,
          kb_lang: locale as string,
          question: inputText,
          search_session_Id: searchSessionId,
          source_type_trigger: typeTrigger,
        })
      );
      clear();
    },
    [sendBIEvent]
  );

  const clickOnResultBI = useCallback(
    async (
      url: string,
      index: number,
      resultSourceType: ArticleType,
      inputText: string,
      searchSessionId: string,
      pageType: PageType,
      itemId?: string,
      itemType?: string,
      id?: string
    ) => {
      await sendBIEvent(
        clickOnAnswer({
          source_name: mapPageTypeToSourceName(pageType),
          item_id: itemId,
          item_type: itemType,
          kb_lang: locale as string,
          clicked_url: url,
          clicked_item_id: id,
          clicked_item_order: `${index + 1}`,
          question: inputText,
          search_session_Id: searchSessionId,
          clicked_item_type: mapArticleTypeToItemType(
            resultSourceType as ArticleType
          ),
          search_result_selection:
            BI.SEARCH_RESULT_SELECTION.SEARCH_BAR_DROPDOWN_LIST,
        })
      );
    },
    [sendBIEvent]
  );
  const searchQuestionBI = useCallback(
    async (
      results: ArticleSearchResultItem[] | ArticleSearchResultItem[],
      pageType: PageType,
      inputText: string,
      searchSessionId: string,
      searchMethod?: string,
      searchVersion?: string,
      itemId?: string,
      itemType?: string,
      inputType?: string
    ) => {
      await sendBIEvent(
        askingAQuestion({
          source_name: mapPageTypeToSourceName(pageType),
          item_id: itemId,
          item_type: itemType,
          kb_lang: locale as string,
          presented_content: JSON.stringify(
            (results as ArticleSearchResultItem[]).map((article, index) => ({
              order: index + 1,
              item_id: article.id,
              locale,
              type: mapArticleTypeToItemType(article.type),
              output_distance: article.distance ? article.distance : null,
            }))
          ),
          question: inputText,
          search_session_Id: searchSessionId,
          search_method: searchMethod,
          search_version: searchVersion,
          input_type: inputType,
        })
      );
    },
    [sendBIEvent]
  );

  const viewResultsGroupBI = useCallback(
    async (
      resultSourceType: ArticleType,
      pageType: PageType,
      searchSessionId: string
    ) => {
      await sendBIEvent(
        hcSearchDropdownSourceSectionOnView({
          source_name: mapPageTypeToSourceName(pageType),
          kb_lang: locale as string,
          source_section_name: mapResourceTypeToItemType(resultSourceType),
          search_session_id: searchSessionId,
        })
      );
    },
    [sendBIEvent]
  );

  return {
    searchQuestionBI,
    clickAllResultsBI,
    clickOnResultBI,
    viewResultsGroupBI,
  };
};
