import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  UserToolbar,
  ToolbarContextSections,
  ContextData,
} from '@wix/answers-common-components';
import {
  Counters,
  CountersResult,
  CountersHelper,
  Article,
} from '@wix/answers-api';
import { useTranslation } from '@wix/wix-i18n-config';
import { AGENT_TOOLBAR, PAGES } from '../../constants';
import { useRouter } from 'next/router';
import { PageData } from '../PageTemplate';
import { Context } from '../../context';
import { PageType } from '../../types';
import Long from 'long';

type AgentToolbarProps = {
  pageType: PageType;
  pageData: PageData;
};

const ToolbarContextSectionsMap = {
  [PAGES.ARTICLE]: ToolbarContextSections.ARTICLE,
  [PAGES.CATEGORY]: ToolbarContextSections.CATEGORY,
  [PAGES.SEARCH_RESULTS]: ToolbarContextSections.NONE,
  [PAGES.HOME]: ToolbarContextSections.NONE,
  [PAGES.ROADMAP]: ToolbarContextSections.NONE,
};

export const AgentToolbar: FunctionComponent<AgentToolbarProps> = ({
  pageType,
  pageData,
}) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const [isExpanded, setIsExpanded] = useState(true);
  const [contextData, setContextData] = React.useState<ContextData>({});
  const [counters, setCounters] = React.useState<Counters>();
  const [relatedTicketsCount, setRelatedTicketsCount] = React.useState(0);
  const { answersApi, isWixEmployee: isAgent, isMobile } = useContext(Context);

  const showAgentToolbar = useMemo(() => isAgent && !isMobile, [isAgent, isMobile]);

  const fetchRelatedTicketsCount = useCallback(async () => {
    const { article } = pageData;
    if (pageType === PAGES.ARTICLE && article?.id && showAgentToolbar) {
      const relatedTickets = await answersApi.tickets.searchAdmin({
        filters: { relatedArticleIds: [article.id] },
        locale: article.locale
      });
      setRelatedTicketsCount(relatedTickets.itemsCount);
    }
  }, [pageData, pageType, answersApi, showAgentToolbar]);

  const fetchCounters = useCallback(async () => {
    const { article } = pageData;
    if (pageType === PAGES.ARTICLE && article?.id && showAgentToolbar) {
      let newCounters: Counters;

      try {
        const articleStatistics = await answersApi.kb.getArticleStatistics({
          id: article?.id,
          locale: locale as string,
        });
        newCounters = {
          helpful: Number(articleStatistics.total_helpful_votes),
          unhelpful: Number(articleStatistics.total_unhelpful_votes),
          views: Number(articleStatistics.last_90_days_views),
          votes: Number(articleStatistics.total_general_votes),
          followers: Number(articleStatistics.total_general_votes),
        };
      } catch (err) {
        const res = await answersApi.articles.getArticleCounters({
          id: article?.id,
          locale: locale as string,
        });
        newCounters = CountersHelper.convertCounterResultsToCounters(
          res as unknown as CountersResult[]
        );
      }

      setCounters(newCounters);
    }
  }, [locale, showAgentToolbar, pageData, pageType, answersApi]);

  useEffect(() => {
    void fetchCounters();
  }, [fetchCounters]);

  useEffect(() => {
    void fetchRelatedTicketsCount();
  }, [fetchRelatedTicketsCount]);

  const getFormattedDate = (date: number) =>
    new Date(date).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });

  useEffect(() => {
    const { article, category } = pageData;
    switch (pageType) {
      case PAGES.ARTICLE:
        if (article) {
          setContextData({
            articleContext: {
              article: { ...(article as unknown as Article), relatedTicketsCount: relatedTicketsCount || article.relatedTicketsCount || 0 },
              updated: article.lastPublishDate
                ? getFormattedDate(
                  new Long(
                    article.lastPublishDate.low,
                    article.lastPublishDate.high,
                    article.lastPublishDate.unsigned
                  ).toNumber()
                )
                : '',
              created: article.creationDate
                ? getFormattedDate(
                  new Long(
                    article.creationDate.low,
                    article.creationDate.high,
                    article.creationDate.unsigned
                  ).toNumber()
                )
                : '',
              counters,
            },
          });
        }
        break;
      case PAGES.CATEGORY:
        if (category) {
          setContextData({
            categoryContext: {},
          });
        }
        break;
    }
  }, [pageData, pageType, counters, relatedTicketsCount]);

  useEffect(() => {
    const bodyElm = document.body;
    if (showAgentToolbar) {
      if (isExpanded) {
        bodyElm.classList.add(AGENT_TOOLBAR.CLASSES.OPEN);
        bodyElm.classList.remove(AGENT_TOOLBAR.CLASSES.CLOSE);
      } else {
        bodyElm.classList.add(AGENT_TOOLBAR.CLASSES.CLOSE);
        bodyElm.classList.remove(AGENT_TOOLBAR.CLASSES.OPEN);
      }
    }
  }, [isExpanded, showAgentToolbar]);

  const getVisibleContext = () => ToolbarContextSectionsMap[pageType] || ToolbarContextSections.NONE;

  return showAgentToolbar ? (
    <UserToolbar
      isExpanded={isExpanded}
      toggleExpansion={() => setIsExpanded(!isExpanded)}
      translate={t}
      visibleContext={getVisibleContext()}
      contextData={contextData}
    />
  ) : null;
};
