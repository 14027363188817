import { useTranslation } from '@wix/wix-i18n-config';
import Image from 'next/image';
import { FunctionComponent } from 'react';
import { Box, Divider } from '@wix/design-system';
import { DATA_HOOKS } from '../../dataHooks';
import { Breadcrumb } from './Breadcrumb';
import css from './index.module.scss';
import classNames from 'classnames';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { EXPERIMENTS } from '../../constants';
export type BreadcrumbsProps = {
  breadcrumbItems: BreadcrumbItem[];
  shallow?: boolean;
  children?: JSX.Element;
  sticky?: boolean;
  ref?: any;
  onCategoryMenuClick?: () => void;
  onHomePageClick?: (text: string) => Promise<void>;
  navCategoryTree?: JSX.Element;
};

export type BreadcrumbItem = {
  label: string;
  id?: string;
  uri?: string;
  onClick?: () => Promise<void>;
};

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({
  breadcrumbItems,
  shallow,
  children,
  onCategoryMenuClick,
  onHomePageClick,
  ref,
  navCategoryTree,
}: BreadcrumbsProps) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments({ readOnly: true });
  const isNewSubheaderDesignEnabled = experiments.enabled(
    EXPERIMENTS.SPECS.NEW_SUBHEADER_DESIGN
  );

  const onMenuKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && onCategoryMenuClick) {
      onCategoryMenuClick();
    }
  };

  return (
    <Box
      verticalAlign="middle"
      dataHook={DATA_HOOKS.BREADCRUMBS}
      ref={ref}
      className={classNames(css.wrapper, {
        [css.newSubheaderDesign]: isNewSubheaderDesignEnabled,
      })}
    >
      <Box
        direction="vertical"
        alignContent={'center'}
        verticalAlign="middle"
        className={css.menu}
      >
        {onCategoryMenuClick && (
          <>
            <div
              className={css.menuButton}
              onClick={onCategoryMenuClick}
              onKeyDown={onMenuKeyDown}
              tabIndex={0}
              data-hook={DATA_HOOKS.BREADCRUMB_CATEGORY_MENU}
            >
              <Image
                src={'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/icons/breadcrumbs-menu.svg'}
                width="18"
                height="18"
                alt=""
                loading="eager"
              />
              <Divider className={css.divider} direction="vertical" />
            </div>
            {navCategoryTree}
          </>
        )}
      </Box>
      <div className={css.scrollableIndicator} />
      <ol
        tabIndex={0}
        className={classNames(css.breadcrumbs, {
          [css.withMenu]: onCategoryMenuClick,
        })}
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        <Box alignContent={'top'} key={'home'} className={css.breadcrumbItem}>
          <Breadcrumb
            hideChevron
            breadcrumbItem={{
              label: t('breadcrumbs.home'),
              uri: '/',
              onClick: async () => onHomePageClick?.(t('breadcrumbs.home')),
            }}
            isLastItem={false}
            index={1}
          />
        </Box>
        {breadcrumbItems.map((breadcrumbItem, index) => {
          const isLastItem = index === breadcrumbItems.length - 1;
          return (
            <Breadcrumb
              key={breadcrumbItem.id || index}
              breadcrumbItem={breadcrumbItem}
              isLastItem={isLastItem}
              index={index + 2}
              shallow={shallow}
            />
          );
        })}
      </ol>
      {children}
    </Box>
  );
};
