import {
  Box,
  Search,
  IconButton,
  Badge,
  IconElement,
  Text,
} from '@wix/design-system';
import {
  ArrowRight as ArrowRightIcon,
  ArrowLeft as ArrowLeftIcon,
  HintSmall as HintSmallIcon,
  AppSmall as AppSmallIcon,
  OnlineProgramsSmall as OnlineProgramsSmallIcon,
  PremiumSmall as PremiumSmallIcon,
  GoogleAdsSmall as GoogleAdsIcon,
  EmailSmall as EmailSmallIcon,
} from '@wix/wix-ui-icons-common';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { DATA_HOOKS } from '../../../../dataHooks';
import { isRTL } from '../../../../utils/internationalization';
import { useRouter } from 'next/router';
import { MAX_SEARCH_INPUT_LENGTH } from '../../../KnowledgeAgentSearchHero';
import { Context } from '../../../../context';
import css from './index.module.scss';

export type SearchBarModalBodyProps = {
  goToSearchResultsPage: (overideQuery?: string) => Promise<void>;
  query: string;
  setQuery: (query: string) => void;
};

type SearchBadge = {
  key: string;
  icon: IconElement;
};

export const BADGES: SearchBadge[] = [
  {
    key: 'search-bar-modal.badge.connect-a-domain',
    icon: <OnlineProgramsSmallIcon />,
  },
  {
    key: 'search-bar-modal.badge.choose-a-premium-plan',
    icon: <PremiumSmallIcon />,
  },
  {
    key: 'search-bar-modal.badge.create-a-dynamic-page',
    icon: <AppSmallIcon />,
  },
  {
    key: 'search-bar-modal.badge.run-google-ads-with-wix',
    icon: <GoogleAdsIcon />,
  },
  {
    key: 'search-bar-modal.badge.connect-an-external-email',
    icon: <EmailSmallIcon />,
  },
];

const MOBILE_BADGES: SearchBadge[] = BADGES.slice(0, 3);

export const SearchBarModalBody: FunctionComponent<SearchBarModalBodyProps> = ({
  goToSearchResultsPage,
  query,
  setQuery,
}) => {
  const [hoveredBadgeIndex, setHoveredBadgeIndex] = useState(-1);
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { isMobile } = useContext(Context);
  const isRTLLanguage = isRTL(locale);

  return (
    <Box direction="vertical" className={css.searchBarModalBody}>
      <Box direction="vertical" gap="6px" align={isMobile ? 'center' : 'left'}>
        <Box
          direction="horizontal"
          gap="6px"
          verticalAlign="middle"
          width="100%"
        >
          <Box
            direction="horizontal"
            className={css.searchWrapper}
            gap="6px"
            width="100%"
          >
            <Search
              placeholder={t(
                `search-bar-modal.placeholder${isMobile ? '-mobile' : ''}`
              )}
              onChange={(event) => setQuery(event.target.value)}
              size="large"
              onClear={() => setQuery('')}
              value={query}
              onEnterPressed={() => goToSearchResultsPage()}
              dataHook={DATA_HOOKS.SEARCH_BAR_MODAL_INPUT}
              className={css.searchInput}
              maxLength={MAX_SEARCH_INPUT_LENGTH}
              autoFocus
            />
            <IconButton
              size={isMobile ? 'tiny' : 'large'}
              onClick={() => goToSearchResultsPage()}
              dataHook={DATA_HOOKS.SEARCH_BAR_MODAL_CTA}
              className={css.searchCTA}
            >
              {isRTLLanguage ? <ArrowLeftIcon /> : <ArrowRightIcon />}
            </IconButton>
          </Box>
        </Box>
        <Box
          direction="horizontal"
          align="center"
          className={css.searchBarModalHint}
          gap={isMobile ? '0' : '3px'}
        >
          {!isMobile && <HintSmallIcon />}
          <Text size={isMobile ? 'tiny' : 'small'}>
            {t('search-bar-modal.hint')}
          </Text>
        </Box>
      </Box>
      <Box
        direction="horizontal"
        gap={isMobile ? '12px' : '11px'}
        className={css.searchBarModalBadges}
        align={isMobile ? 'center' : 'left'}
      >
        {(isMobile ? MOBILE_BADGES : BADGES).map(
          (badge: SearchBadge, index: number) => (
            <div
              className={css.searchBarModalBadge}
              key={badge.key}
              onMouseEnter={() => setHoveredBadgeIndex(index)}
              onMouseLeave={() => setHoveredBadgeIndex(-1)}
              onClick={() => goToSearchResultsPage(t(badge.key))}
              data-hook={DATA_HOOKS.SEARCH_BAR_MODAL_BADGE}
              tabIndex={0}
            >
              <Badge
                skin={index === hoveredBadgeIndex ? 'general' : 'neutralLight'}
                prefixIcon={badge.icon}
              >
                {t(badge.key)}
              </Badge>
            </div>
          )
        )}
      </Box>
    </Box>
  );
};
