import { NextApiRequest } from 'next';
import parserUA from 'ua-parser-js';

export const isMobile = (context?: { isMobile?: boolean }): boolean =>
  context?.isMobile ||
  (typeof window !== 'undefined' && window.innerWidth < 767);

export const isMobileSSR = (req?: NextApiRequest) => {
  const ua = parserUA(req ? req.headers['user-agent'] : navigator.userAgent);
  return ua.device.type === 'mobile';
};

export const isTabletSSR = (req?: NextApiRequest) => {
  const ua = parserUA(req ? req.headers['user-agent'] : navigator.userAgent);
  return ua.device.type === 'tablet';
};

export const isTablet = (context?: { isTablet?: boolean }): boolean =>
  context?.isTablet ||
  (typeof window !== 'undefined' &&
    window.innerWidth > 767 &&
    window.innerWidth < 1216);

export const isSledUserAgent = (userAgent?: string) =>
  (userAgent || navigator.userAgent).includes('AdamIsTheChaosMonkey');
