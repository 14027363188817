/* eslint-disable max-lines */
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from '@wix/wix-i18n-config';
import {
  Box,
  FloatingNotification,
  Heading,
  MessageModalLayout,
  Pagination,
  Search,
  Text,
} from '@wix/design-system';
import css from './index.module.scss';
import { ArticleSortType, TsUtils } from '@wix/answers-api';
import {
  ArticleSearchResult,
  ArticleSearchResultItem,
  Label,
  RoadmapETAorVotedFilter,
  RoadmapFeatureResolution,
  RoadmapFilter,
  SortAndFilterBiMetaData,
} from '../../types';
import { Context } from '../../context';
import { PAGE_SIZE } from '../../pages/roadmap';
import { isMobile } from '../../utils/userAgent';
import { isSmallScreenSize } from './RoadmapSortAndFilter';
import { RoadmapHeader } from './RoadmapHeader';
import RoadmapArticleListItem from './RoadmapArticleListItem';
import { DATA_HOOKS } from '../../dataHooks';
import { useRouter } from 'next/router';
import { useExperiments } from '@wix/fe-essentials-standalone';
import {
  BI,
  EXPERIMENTS,
  MAX_SEARCH_LENGTH,
  SEARCH_DEBOUNCE,
  StorageKeys,
} from '../../constants';
import {
  helpCenterModals,
  roadmapWishlistFormClick,
} from '@wix/bi-logger-new-help-center/v2';
import {
  getLocalStorage,
  setLocalStorage,
} from '../../utils/webStorageWrapper';
import { useBI } from '../../hooks/useBI';
import { pushUrl } from '../InnerLink';
import { Flag } from '@wix/wix-ui-icons-common';
import { RenderHTML } from '../RenderHTML';
import { WalkthroughPopover } from './WalkthroughPopover';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useRoadmapHeaderBi } from './RoadmapHeader/bi';
import { RoadmapEmptyState } from './RoadmapEmptyState';
import { RequestFeatureUseDesktopModal } from './DesktopOnlyModal';
import { RoadmapFiltersHeader } from './RoadmapFiltersHeader';
const RoadmapFeatureRequest = dynamic(() => import('../RoadmapFeatureRequest'));

export enum WalkthroughLevels {
  SEARCH = 1,
  SELECT_PRODUCT = 2,
  FILTERS = 3,
}
export type ApplySortAndFilterFn = (
  roadmapFilter: RoadmapETAorVotedFilter,
  resolution?: RoadmapFeatureResolution
) => Promise<void>;

export type RoadmapArticleListProps = {
  articleSearchResult: ArticleSearchResult;
  roadmapFilter: RoadmapFilter;
  onChangeRoadmapFilter: (filter: RoadmapFilter) => void;
  currentPage: number;
  onChangePage: (pageNumber: number) => void;
  followArticle: (articleId: string) => void;
  unfollowArticle: (articleId: string) => void;
  onSelectArticle: (article: ArticleSearchResultItem) => void;
  locale: string;
  userFollowedArticle: string[];
  labels: Label[];
  articlePopulationStatus: Record<string, boolean>;
  togglePopulationStatus: (featureId: string) => void;
  apply: ApplySortAndFilterFn;
  onSearch: (text: string) => void;
  searchTerm: string;
  isScrolled: boolean;
  onChangeTriggerBiMetaData: (
    triggerBiMetaData: SortAndFilterBiMetaData
  ) => void;
};
export const ALL_SORT_OPTION = [
  ArticleSortType.LAST_PUBLISH_DATE_DESC,
  ArticleSortType.VOTES_DESC,
];

export const allFilterOptions = TsUtils.enumToArray(
  RoadmapETAorVotedFilter
) as RoadmapETAorVotedFilter[];

export const RoadmapArticleList: FunctionComponent<RoadmapArticleListProps> = ({
  articleSearchResult,
  roadmapFilter,
  onChangeRoadmapFilter,
  followArticle,
  unfollowArticle,
  labels,
  onSelectArticle,
  currentPage,
  onChangePage,
  locale,
  userFollowedArticle,
  apply,
  onSearch,
  searchTerm,
  articlePopulationStatus,
  togglePopulationStatus,
  isScrolled,
  onChangeTriggerBiMetaData,
}) => {
  const [articleList, setArticleList] = useState(articleSearchResult.items);
  const [totalLength, setTotalLength] = useState(
    articleSearchResult.itemsCount
  );
  const [showDesktopOnlyModal, setShowDesktopOnlyModal] = useState(false);
  const [showWalkthroughModal, setShowWalkthroughModal] = useState(false);
  const [walkthroughStep, setWalkthroughStep] =
    useState<WalkthroughLevels | null>(null);
  const [showAutoEnrollmentNotification, setShowAutoEnrollmentNotification] =
    useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { query } = useRouter();
  const { showRequestFeature, origin } = query;
  const { t } = useTranslation();
  const context = useContext(Context);
  const isMobileView = isMobile(context);
  const headerWrapperRef = useRef<HTMLDivElement | null>(null);
  const { isWixStudioUser } = context;
  const { sendBIEvent } = useBI();
  const windowSize = useWindowSize();
  const { experiments } = useExperiments({ readOnly: true });
  const biLogger = useRoadmapHeaderBi();

  React.useEffect(() => {
    if (windowSize.width) {
      setIsSmallScreen(isSmallScreenSize(windowSize.width));
    }
  }, [windowSize.width]);

  useEffect(() => {
    const shouldShow =
      experiments.enabled(EXPERIMENTS.SPECS.AUTO_ENROLLMENT) &&
      isWixStudioUser &&
      localStorage &&
      !getLocalStorage(StorageKeys.AUTO_ENROLLMENT_NOTIFICATION_DISMISSED);
    if (shouldShow && !showAutoEnrollmentNotification) {
      biLogger.enrollmentFeatureBannerShow({
        content: t('roadmap.page.auto-enrollment-notification'),
      });
    }
    setShowAutoEnrollmentNotification(shouldShow);
  }, [isWixStudioUser, experiments]);

  const dismissAutoEnrollmentNotification = useCallback(() => {
    setShowAutoEnrollmentNotification(false);
    return setLocalStorage(
      StorageKeys.AUTO_ENROLLMENT_NOTIFICATION_DISMISSED,
      'true'
    );
  }, []);

  useEffect(() => {
    setArticleList(articleSearchResult.items);
    setTotalLength(articleSearchResult.itemsCount);
  }, [articleSearchResult]);

  const onChangePageNumber = (event: {
    event: React.SyntheticEvent<Element, Event>;
    page: number;
  }): void => {
    onChangePage(event.page);
  };

  const getRoadmapLabelName = (labelId: string) => {
    const roadmapLabel = labels.find((l) => l.id === labelId);
    return roadmapLabel ? roadmapLabel.name : null;
  };

  const setFeatureRequestModalVisible = async (show: boolean) => {
    if (isWixStudioUser) {
      const newUrl = new URL(window.location.href);
      if (show) {
        newUrl.searchParams.set('showRequestFeature', 'true');
      } else {
        newUrl.searchParams.delete('showRequestFeature');
        newUrl.searchParams.delete('origin');
      }
      await pushUrl(`${newUrl.pathname}${newUrl.search}`, true);
    }
  };

  useEffect(() => {
    if (showRequestFeature && isWixStudioUser) {
      void sendBIEvent(
        roadmapWishlistFormClick({
          source_name: BI.SOURCE_NAMES.ROADMAP,
          clicked_item_type: BI.CLICKED_ITEM_TYPES.EXTERNAL_FORM_LINK,
          kb_lang: locale as string,
          origin_url: encodeURIComponent(document.referrer),
          origin: origin?.toString(),
        })
      );
    }
    const showRoadmapTour = getLocalStorage('showRoadmapV2Tour');
    if (showRoadmapTour !== 'false') {
      void sendBIEvent(
        helpCenterModals({
          source_name: BI.SOURCE_NAMES.ROADMAP,
          modal_name: BI.MODAL_NAME.ROADMAP_WALKTHROUGH,
          screen_name: BI.ROADMAP_WALKTHROUGH_SCREEN_NAME.WELCOME,
          kb_lang: locale as string,
          action_type: BI.ACTION_TYPE.MODAL_LOAD,
        })
      );
      setShowWalkthroughModal(showRoadmapTour !== 'false');
    }
  }, []);

  const onSelectLabelFromArticle = (label: Label) => {
    onChangeRoadmapFilter({ ...roadmapFilter, labels: [label] });
  };
  const numPages = Math.ceil(totalLength / PAGE_SIZE);

  const onSelectStatus = (resolution: RoadmapFeatureResolution) => {
    onChangeRoadmapFilter({
      ...roadmapFilter,
      resolution,
    });
  };
  const renderArticleItem = (article: ArticleSearchResultItem, idx: number) => {
    const articleLabelsNames = article.labels
      ? article.labels
          .filter(
            (articleLabel) => getRoadmapLabelName(articleLabel.id) !== null
          )
          .map((roadmapArticleLabel) => ({
            name: getRoadmapLabelName(roadmapArticleLabel.id) || '',
            id: roadmapArticleLabel.id,
          }))
      : [];
    return (
      <div key={article.id} className={css.articleListItemWrapper}>
        <RoadmapArticleListItem
          key={article.id}
          article={article}
          articleLabels={articleLabelsNames}
          onSelectLabel={onSelectLabelFromArticle}
          followArticle={followArticle}
          unFollowArticle={unfollowArticle}
          onSelectArticle={onSelectArticle}
          locale={locale}
          userFollowedArticle={userFollowedArticle}
          articlePopulationStatus={articlePopulationStatus}
          togglePopulationStatus={togglePopulationStatus}
          onSelectStatus={onSelectStatus}
          filter={roadmapFilter.ETAorVoted}
        />
        {idx !== articleList.length - 1 && <div className={css.divider} />}
      </div>
    );
  };

  const onChangeText = (e: any) => {
    onSearch(e.target.value);
  };

  const results = (
    <Box
      className={`${css.results} ${isScrolled ? css.scrolled : ''} ${
        isMobileView ? css.mobile : ''
      }`}
    >
      <Text className={css.resultsText} size="medium">
        {searchTerm
          ? t('roadmap.search.results', {
              number: totalLength,
            })
          : t('roadmap.search.results.default', {
              number: totalLength,
            })}
        {searchTerm && (
          <Box inline paddingLeft="SP1">
            <Text className={css.resultsText} size="medium" weight="bold">
              {`“${searchTerm}”`}
            </Text>
          </Box>
        )}
      </Text>
    </Box>
  );

  const onStartTour = async () => {
    setShowWalkthroughModal(false);
    await sendBIEvent(
      helpCenterModals({
        source_name: BI.SOURCE_NAMES.ROADMAP,
        modal_name: BI.MODAL_NAME.ROADMAP_WALKTHROUGH,
        screen_name: BI.ROADMAP_WALKTHROUGH_SCREEN_NAME.WELCOME,
        kb_lang: locale as string,
        action_type: BI.ACTION_TYPE.MODAL_CLICK,
        clicked_button_name: BI.CLICKED_ITEM_TYPES.WALKTHROUGH_START_TOUR,
      })
    );
    setWalkthroughStep(WalkthroughLevels.SEARCH);
  };
  const onSkipTour = async () => {
    setShowWalkthroughModal(false);
    setLocalStorage('showRoadmapV2Tour', 'false');
    await sendBIEvent(
      helpCenterModals({
        source_name: BI.SOURCE_NAMES.ROADMAP,
        modal_name: BI.MODAL_NAME.ROADMAP_WALKTHROUGH,
        screen_name: BI.ROADMAP_WALKTHROUGH_SCREEN_NAME.WELCOME,
        kb_lang: locale as string,
        action_type: BI.ACTION_TYPE.MODAL_CLICK,
        clicked_button_name: BI.CLICKED_ITEM_TYPES.SKIP,
      })
    );
  };

  const onCloseTourModal = async () => {
    await sendBIEvent(
      helpCenterModals({
        source_name: BI.SOURCE_NAMES.ROADMAP,
        modal_name: BI.MODAL_NAME.ROADMAP_WALKTHROUGH,
        screen_name: BI.ROADMAP_WALKTHROUGH_SCREEN_NAME.WELCOME,
        kb_lang: locale as string,
        action_type: BI.ACTION_TYPE.MODAL_CLICK,
        clicked_button_name: BI.CLICKED_ITEM_TYPES.CLOSE,
      })
    );
    setShowWalkthroughModal(false);
  };

  const walkthroughModalTitle = isMobileView ? (
    <Text className={css.walkthroughModalTitle} size="medium" weight="bold">
      {t('roadmap.walkthrough.modal.title')}
    </Text>
  ) : (
    <Heading className={css.walkthroughModalTitle} size="large">
      {t('roadmap.walkthrough.modal.title')}
    </Heading>
  );

  return (
    <Box
      key="article-list"
      direction="vertical"
      align="center"
      width={isMobileView ? undefined : '100%'}
      className={`${css.articleList}`}
    >
      {isMobileView && showAutoEnrollmentNotification && (
        <FloatingNotification
          fullWidth
          onClose={dismissAutoEnrollmentNotification}
          className={css.roadMapAutoEnrollmentNotification}
          prefixIcon={<Flag />}
          type="standard"
          text={
            <RenderHTML html={t('roadmap.page.auto-enrollment-notification')} />
          }
        />
      )}
      {showWalkthroughModal && (
        <MessageModalLayout
          className={css.walkthroughModal}
          onCloseButtonClick={onCloseTourModal}
          primaryButtonOnClick={onStartTour}
          secondaryButtonOnClick={onSkipTour}
          primaryButtonText={t('roadmap.walkthrough.modal.start')}
          secondaryButtonText={t('roadmap.walkthrough.modal.cancel')}
          title={walkthroughModalTitle}
          content={
            <Text size="medium" weight="thin">
              {t('roadmap.walkthrough.modal.content')}
            </Text>
          }
        />
      )}
      {showDesktopOnlyModal &&
        experiments.enabled(EXPERIMENTS.SPECS.IMPROVE_SIMILARITY_MECHANISM) && (
          <RequestFeatureUseDesktopModal
            shown={showDesktopOnlyModal}
            onClose={() => setShowDesktopOnlyModal(false)}
          />
        )}
      <Box className={css.articleListInner} direction="vertical">
        <Box
          className={`${css.headerWrapper} ${isScrolled ? css.scrolled : ''} ${
            isMobileView ? css.mobile : ''
          }`}
          ref={headerWrapperRef}
          align="space-between"
          direction="vertical"
        >
          {!isMobileView && (
            <RoadmapHeader roadmapLabels={labels} isScrolled={isScrolled} />
          )}
          <Box
            align="space-between"
            direction={isMobileView ? 'horizontal' : 'vertical'}
            className={css.searchAndFiltersPanel}
          >
            <WalkthroughPopover
              walkthroughLevels={WalkthroughLevels.SEARCH}
              opened={walkthroughStep === WalkthroughLevels.SEARCH}
              placement={isMobileView ? 'bottom-start' : 'left-start'}
              onClose={() => setWalkthroughStep(null)}
              onChangeLevel={(walkthroughLevels: WalkthroughLevels) =>
                setWalkthroughStep(walkthroughLevels)
              }
            >
              <Search
                className={css.roadmapSearch}
                dataHook={DATA_HOOKS.ROADMAP_SEARCH}
                autoFocus={isMobileView ? false : true}
                onChange={onChangeText}
                border={isMobileView ? 'round' : 'bottomLine'}
                placeholder={t('roadmap.search.placeholder')}
                debounceMs={SEARCH_DEBOUNCE}
                maxLength={MAX_SEARCH_LENGTH}
                onClear={() => onSearch('')}
                forceFocus={isMobileView ? false : true}
              />
            </WalkthroughPopover>
            {!isSmallScreen &&
            !isMobileView &&
            walkthroughStep !== undefined ? (
              <WalkthroughPopover
                walkthroughLevels={WalkthroughLevels.FILTERS}
                opened={walkthroughStep === WalkthroughLevels.FILTERS}
                placement="bottom"
                onClose={() => setWalkthroughStep(null)}
                onChangeLevel={(walkthroughLevels: WalkthroughLevels) =>
                  setWalkthroughStep(walkthroughLevels)
                }
              >
                <RoadmapFiltersHeader
                  labels={labels}
                  roadmapFilter={roadmapFilter}
                  onChangeRoadmapFilter={onChangeRoadmapFilter}
                  apply={apply}
                  onSearch={onSearch}
                  isScrolled={isScrolled}
                  onChangeTriggerBiMetaData={onChangeTriggerBiMetaData}
                  walkthroughStep={walkthroughStep}
                  setWalkthroughStep={setWalkthroughStep}
                  setShowDesktopOnlyModal={setShowDesktopOnlyModal}
                  locale={locale}
                  setFeatureRequestModalVisible={setFeatureRequestModalVisible}
                />
              </WalkthroughPopover>
            ) : (
              <RoadmapFiltersHeader
                labels={labels}
                roadmapFilter={roadmapFilter}
                onChangeRoadmapFilter={onChangeRoadmapFilter}
                apply={apply}
                onSearch={onSearch}
                isScrolled={isScrolled}
                onChangeTriggerBiMetaData={onChangeTriggerBiMetaData}
                walkthroughStep={walkthroughStep}
                setWalkthroughStep={setWalkthroughStep}
                setShowDesktopOnlyModal={setShowDesktopOnlyModal}
                locale={locale}
                setFeatureRequestModalVisible={setFeatureRequestModalVisible}
              />
            )}
          </Box>
          {isMobileView && results}
          {isWixStudioUser && labels && isMobileView && (
            <RoadmapFeatureRequest
              labels={labels}
              isOpen={!!showRequestFeature}
              onClose={() => setFeatureRequestModalVisible(false)}
            />
          )}
        </Box>
        {!isMobileView && results}
        {articleList.length ? (
          <Box direction="vertical" className={css.articleListWrapper}>
            {articleList.map((article, idx) => renderArticleItem(article, idx))}
          </Box>
        ) : (
          <RoadmapEmptyState ETAorVotedFilter={roadmapFilter.ETAorVoted} />
        )}
        {totalLength > PAGE_SIZE ? (
          <Box
            paddingTop="30px"
            align="center"
            direction="horizontal"
            width={'100%'}
          >
            <Pagination
              className={css.roadmapPagination}
              onChange={onChangePageNumber}
              currentPage={currentPage}
              totalPages={numPages}
            />
          </Box>
        ) : null}
        {
          <Box width={'100%'}>
            <RenderHTML
              textElement={{
                size: 'tiny',
                weight: 'thin',
                className: css.noteMark,
              }}
              className={css.NumberOfResultsMobile}
              html={t('roadmap.article-list.note-mark')}
            />
          </Box>
        }
      </Box>
    </Box>
  );
};
